const Teamdata_2023 = {
    Core: [
        {Position:"President", Name:'Kunal Taware',Insta:"https://www.instagram.com/kunal_.0204/",Linked:"https://www.linkedin.com/in/kunal-taware-a76832233/",img:'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSG8zpeBAwHX8fSNly16RLQUa-iNc6Ws7H9XfqNxSqu9MhAYCg2XyywcrXRyEKJoC2Gsho'},
        {Position:"Vice President", Name:'Sanika Kotgire',Insta:"https://www.instagram.com/k__sanika/",img:'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSG8zpeBAwHX8fSNly16RLQUa-iNc6Ws7H9XfqNxSqu9MhAYCg2XyywcrXRyEKJoC2Gsho'},
        {Position:"Vice President", Name:'Sejal Behera',img:'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSG8zpeBAwHX8fSNly16RLQUa-iNc6Ws7H9XfqNxSqu9MhAYCg2XyywcrXRyEKJoC2Gsho'},
        {Position:"Secretary", Name:'Shazia',img:'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSG8zpeBAwHX8fSNly16RLQUa-iNc6Ws7H9XfqNxSqu9MhAYCg2XyywcrXRyEKJoC2Gsho'},
        {Position:"Treasurer", Name:'Labhesh Dharmik',img:'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSG8zpeBAwHX8fSNly16RLQUa-iNc6Ws7H9XfqNxSqu9MhAYCg2XyywcrXRyEKJoC2Gsho'},

    ],
    Tech: [
        {Position:"Team Lead", Name:'Arpit Bansal',quote:"Always work hard",img:'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSG8zpeBAwHX8fSNly16RLQUa-iNc6Ws7H9XfqNxSqu9MhAYCg2XyywcrXRyEKJoC2Gsho'},
        { Name:'Anushka Girish',quote:"Always work hard",img:'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSG8zpeBAwHX8fSNly16RLQUa-iNc6Ws7H9XfqNxSqu9MhAYCg2XyywcrXRyEKJoC2Gsho'},
        { Name:'Hansika Bhambhaney',quote:"Always work hard",img:'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSG8zpeBAwHX8fSNly16RLQUa-iNc6Ws7H9XfqNxSqu9MhAYCg2XyywcrXRyEKJoC2Gsho'},
        { Name:'Madhur Patil',quote:"Always work hard",img:'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSG8zpeBAwHX8fSNly16RLQUa-iNc6Ws7H9XfqNxSqu9MhAYCg2XyywcrXRyEKJoC2Gsho'},
        { Name:'Nidhi Phophaliya',quote:"Always work hard",img:'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSG8zpeBAwHX8fSNly16RLQUa-iNc6Ws7H9XfqNxSqu9MhAYCg2XyywcrXRyEKJoC2Gsho'},
        { Name:'Sudarshan Goyal',quote:"Always work hard",img:'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSG8zpeBAwHX8fSNly16RLQUa-iNc6Ws7H9XfqNxSqu9MhAYCg2XyywcrXRyEKJoC2Gsho'},
        { Name:'Taher Kharoda',quote:"Always work hard",img:'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSG8zpeBAwHX8fSNly16RLQUa-iNc6Ws7H9XfqNxSqu9MhAYCg2XyywcrXRyEKJoC2Gsho'},
        { Name:'Ujjwal Kulkarni',quote:"Always work hard",img:'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSG8zpeBAwHX8fSNly16RLQUa-iNc6Ws7H9XfqNxSqu9MhAYCg2XyywcrXRyEKJoC2Gsho'},
    ],
    Social: [
        {Position:"Team Lead", Name:'Adrija Santra ',quote:"Always work hard",img:'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSG8zpeBAwHX8fSNly16RLQUa-iNc6Ws7H9XfqNxSqu9MhAYCg2XyywcrXRyEKJoC2Gsho'},
        { Name:'Aadish Banode',quote:"Always work hard",img:'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSG8zpeBAwHX8fSNly16RLQUa-iNc6Ws7H9XfqNxSqu9MhAYCg2XyywcrXRyEKJoC2Gsho'},
        { Name:'Diksha Patil',quote:"Always work hard",img:'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSG8zpeBAwHX8fSNly16RLQUa-iNc6Ws7H9XfqNxSqu9MhAYCg2XyywcrXRyEKJoC2Gsho'},
        { Name:'Kunal Mirchandani',quote:"Always work hard",img:'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSG8zpeBAwHX8fSNly16RLQUa-iNc6Ws7H9XfqNxSqu9MhAYCg2XyywcrXRyEKJoC2Gsho'},
        { Name:'Priya Kutwal',quote:"Always work hard",img:'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSG8zpeBAwHX8fSNly16RLQUa-iNc6Ws7H9XfqNxSqu9MhAYCg2XyywcrXRyEKJoC2Gsho'},
        { Name:'Ashwin Ghadi',quote:"Always work hard",img:'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSG8zpeBAwHX8fSNly16RLQUa-iNc6Ws7H9XfqNxSqu9MhAYCg2XyywcrXRyEKJoC2Gsho'},
    ],
    Design: [
        {Position:"Team Lead", Name:'Khushna Kazi',quote:"Always work hard",img:'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSG8zpeBAwHX8fSNly16RLQUa-iNc6Ws7H9XfqNxSqu9MhAYCg2XyywcrXRyEKJoC2Gsho'},
        { Name:'Sarthak Salunke',quote:"Always work hard",img:'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSG8zpeBAwHX8fSNly16RLQUa-iNc6Ws7H9XfqNxSqu9MhAYCg2XyywcrXRyEKJoC2Gsho'},
        { Name:'Soham Jagtap',quote:"Always work hard",img:'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSG8zpeBAwHX8fSNly16RLQUa-iNc6Ws7H9XfqNxSqu9MhAYCg2XyywcrXRyEKJoC2Gsho'},
        { Name:'Vatsalya',quote:"Always work hard",img:'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSG8zpeBAwHX8fSNly16RLQUa-iNc6Ws7H9XfqNxSqu9MhAYCg2XyywcrXRyEKJoC2Gsho'},
        { Name:'Akshat Dubey',quote:"Always work hard",img:'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSG8zpeBAwHX8fSNly16RLQUa-iNc6Ws7H9XfqNxSqu9MhAYCg2XyywcrXRyEKJoC2Gsho'},
        { Name:'Tanuja Jagtap',quote:"Always work hard",img:'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSG8zpeBAwHX8fSNly16RLQUa-iNc6Ws7H9XfqNxSqu9MhAYCg2XyywcrXRyEKJoC2Gsho'},
        { Name:'Taher Kharoda',quote:"Always work hard",img:'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSG8zpeBAwHX8fSNly16RLQUa-iNc6Ws7H9XfqNxSqu9MhAYCg2XyywcrXRyEKJoC2Gsho'},
        { Name:'Tanay Kumar',quote:"Always work hard",img:'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSG8zpeBAwHX8fSNly16RLQUa-iNc6Ws7H9XfqNxSqu9MhAYCg2XyywcrXRyEKJoC2Gsho'},
    ],
    Marketing: [
        {Position:"Team Lead", Name:'Vedant Deshpande',quote:"Always work hard",img:'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSG8zpeBAwHX8fSNly16RLQUa-iNc6Ws7H9XfqNxSqu9MhAYCg2XyywcrXRyEKJoC2Gsho'},
        { Name:'Tanvi Bokade',quote:"Always work hard",img:'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSG8zpeBAwHX8fSNly16RLQUa-iNc6Ws7H9XfqNxSqu9MhAYCg2XyywcrXRyEKJoC2Gsho'},
        { Name:'Aditya Kulkarni',quote:"Always work hard",img:'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSG8zpeBAwHX8fSNly16RLQUa-iNc6Ws7H9XfqNxSqu9MhAYCg2XyywcrXRyEKJoC2Gsho'},
        { Name:'Omkar Gade',quote:"Always work hard",img:'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSG8zpeBAwHX8fSNly16RLQUa-iNc6Ws7H9XfqNxSqu9MhAYCg2XyywcrXRyEKJoC2Gsho'},
        { Name:'Varun Damal ',quote:"Always work hard",img:'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSG8zpeBAwHX8fSNly16RLQUa-iNc6Ws7H9XfqNxSqu9MhAYCg2XyywcrXRyEKJoC2Gsho'},
        { Name:'Keerthi Devarakonda',quote:"Always work hard",img:'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSG8zpeBAwHX8fSNly16RLQUa-iNc6Ws7H9XfqNxSqu9MhAYCg2XyywcrXRyEKJoC2Gsho'},
    ],
    Event: [
        {Position:"Team Lead", Name:'Harshwardhan Patil',quote:"Always work hard",img:'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSG8zpeBAwHX8fSNly16RLQUa-iNc6Ws7H9XfqNxSqu9MhAYCg2XyywcrXRyEKJoC2Gsho'},
        { Name:'Gary Rodriques',quote:"Always work hard",img:'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSG8zpeBAwHX8fSNly16RLQUa-iNc6Ws7H9XfqNxSqu9MhAYCg2XyywcrXRyEKJoC2Gsho'},
        { Name:'Uttam Gupta',quote:"Always work hard",img:'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSG8zpeBAwHX8fSNly16RLQUa-iNc6Ws7H9XfqNxSqu9MhAYCg2XyywcrXRyEKJoC2Gsho'},
        { Name:'Ifra Shaikh',quote:"Always work hard",img:'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSG8zpeBAwHX8fSNly16RLQUa-iNc6Ws7H9XfqNxSqu9MhAYCg2XyywcrXRyEKJoC2Gsho'},
        { Name:'Shashank Naik',quote:"Always work hard",img:'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSG8zpeBAwHX8fSNly16RLQUa-iNc6Ws7H9XfqNxSqu9MhAYCg2XyywcrXRyEKJoC2Gsho'},
        { Name:'Yashraj Nikam ',quote:"Always work hard",img:'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSG8zpeBAwHX8fSNly16RLQUa-iNc6Ws7H9XfqNxSqu9MhAYCg2XyywcrXRyEKJoC2Gsho'},
        { Name:'Vaishnavi Yadhav',quote:"Always work hard",img:'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSG8zpeBAwHX8fSNly16RLQUa-iNc6Ws7H9XfqNxSqu9MhAYCg2XyywcrXRyEKJoC2Gsho'},
    ],    
  };
const Teamdata_2022 = {
    Core: [
        {Position:"President", Name:'Atharv Patil',quote:"Always work hard",img:'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSG8zpeBAwHX8fSNly16RLQUa-iNc6Ws7H9XfqNxSqu9MhAYCg2XyywcrXRyEKJoC2Gsho'},
        {Position:"Vice President", Name:'Purvika Gore',quote:"Always work hard",img:'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSG8zpeBAwHX8fSNly16RLQUa-iNc6Ws7H9XfqNxSqu9MhAYCg2XyywcrXRyEKJoC2Gsho'},

    ],
    Tech: [
        {Position:"Team Lead", Name:'Gurmehar Singh',quote:"Always work hard",img:'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSG8zpeBAwHX8fSNly16RLQUa-iNc6Ws7H9XfqNxSqu9MhAYCg2XyywcrXRyEKJoC2Gsho'},
        { Name:'Arpit Bansal',quote:"Always work hard",img:'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSG8zpeBAwHX8fSNly16RLQUa-iNc6Ws7H9XfqNxSqu9MhAYCg2XyywcrXRyEKJoC2Gsho'},
        { Name:'Anushka Girish',quote:"Always work hard",img:'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSG8zpeBAwHX8fSNly16RLQUa-iNc6Ws7H9XfqNxSqu9MhAYCg2XyywcrXRyEKJoC2Gsho'},
        { Name:'Madhur Patil',quote:"Always work hard",img:'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSG8zpeBAwHX8fSNly16RLQUa-iNc6Ws7H9XfqNxSqu9MhAYCg2XyywcrXRyEKJoC2Gsho'},
        { Name:'Nidhi Phophaliya',quote:"Always work hard",img:'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSG8zpeBAwHX8fSNly16RLQUa-iNc6Ws7H9XfqNxSqu9MhAYCg2XyywcrXRyEKJoC2Gsho'},
        { Name:'Ujjwal Kulkarni',quote:"Always work hard",img:'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSG8zpeBAwHX8fSNly16RLQUa-iNc6Ws7H9XfqNxSqu9MhAYCg2XyywcrXRyEKJoC2Gsho'},
    ],
    Social: [
        {Position:"Team Lead", Name:'Pranav Chavan',quote:"Always work hard",img:'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSG8zpeBAwHX8fSNly16RLQUa-iNc6Ws7H9XfqNxSqu9MhAYCg2XyywcrXRyEKJoC2Gsho'},
        { Name:'Aadish Banode',quote:"Always work hard",img:'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSG8zpeBAwHX8fSNly16RLQUa-iNc6Ws7H9XfqNxSqu9MhAYCg2XyywcrXRyEKJoC2Gsho'},
        { Name:'Diksha Patil',quote:"Always work hard",img:'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSG8zpeBAwHX8fSNly16RLQUa-iNc6Ws7H9XfqNxSqu9MhAYCg2XyywcrXRyEKJoC2Gsho'},
        { Name:'Kunal Mirchandani',quote:"Always work hard",img:'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSG8zpeBAwHX8fSNly16RLQUa-iNc6Ws7H9XfqNxSqu9MhAYCg2XyywcrXRyEKJoC2Gsho'},
        { Name:'Priya Kutwal',quote:"Always work hard",img:'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSG8zpeBAwHX8fSNly16RLQUa-iNc6Ws7H9XfqNxSqu9MhAYCg2XyywcrXRyEKJoC2Gsho'},
        { Name:'Ashwin Ghadi',quote:"Always work hard",img:'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSG8zpeBAwHX8fSNly16RLQUa-iNc6Ws7H9XfqNxSqu9MhAYCg2XyywcrXRyEKJoC2Gsho'},
    ],
    Design: [
        {Position:"Team Lead", Name:'Nikhil Mahajan',quote:"Always work hard",img:'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSG8zpeBAwHX8fSNly16RLQUa-iNc6Ws7H9XfqNxSqu9MhAYCg2XyywcrXRyEKJoC2Gsho'},
        { Name:'Sarthak Salunke',quote:"Always work hard",img:'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSG8zpeBAwHX8fSNly16RLQUa-iNc6Ws7H9XfqNxSqu9MhAYCg2XyywcrXRyEKJoC2Gsho'},
        { Name:'Soham Jagtap',quote:"Always work hard",img:'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSG8zpeBAwHX8fSNly16RLQUa-iNc6Ws7H9XfqNxSqu9MhAYCg2XyywcrXRyEKJoC2Gsho'},
        { Name:'Vatsalya',quote:"Always work hard",img:'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSG8zpeBAwHX8fSNly16RLQUa-iNc6Ws7H9XfqNxSqu9MhAYCg2XyywcrXRyEKJoC2Gsho'},
        { Name:'Akshat Dubey',quote:"Always work hard",img:'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSG8zpeBAwHX8fSNly16RLQUa-iNc6Ws7H9XfqNxSqu9MhAYCg2XyywcrXRyEKJoC2Gsho'},
        { Name:'Tanuja Jagtap',quote:"Always work hard",img:'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSG8zpeBAwHX8fSNly16RLQUa-iNc6Ws7H9XfqNxSqu9MhAYCg2XyywcrXRyEKJoC2Gsho'},
        { Name:'Taher Kharoda',quote:"Always work hard",img:'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSG8zpeBAwHX8fSNly16RLQUa-iNc6Ws7H9XfqNxSqu9MhAYCg2XyywcrXRyEKJoC2Gsho'},
        { Name:'Tanay Kumar',quote:"Always work hard",img:'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSG8zpeBAwHX8fSNly16RLQUa-iNc6Ws7H9XfqNxSqu9MhAYCg2XyywcrXRyEKJoC2Gsho'},
    ],
    Marketing: [
        {Position:"Team Lead", Name:'Sahil Shah',quote:"Always work hard",img:'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSG8zpeBAwHX8fSNly16RLQUa-iNc6Ws7H9XfqNxSqu9MhAYCg2XyywcrXRyEKJoC2Gsho'},
        { Name:'Tanvi Bokade',quote:"Always work hard",img:'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSG8zpeBAwHX8fSNly16RLQUa-iNc6Ws7H9XfqNxSqu9MhAYCg2XyywcrXRyEKJoC2Gsho'},
        { Name:'Aditya Kulkarni',quote:"Always work hard",img:'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSG8zpeBAwHX8fSNly16RLQUa-iNc6Ws7H9XfqNxSqu9MhAYCg2XyywcrXRyEKJoC2Gsho'},
        { Name:'Omkar Gade',quote:"Always work hard",img:'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSG8zpeBAwHX8fSNly16RLQUa-iNc6Ws7H9XfqNxSqu9MhAYCg2XyywcrXRyEKJoC2Gsho'},
        { Name:'Varun Damal ',quote:"Always work hard",img:'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSG8zpeBAwHX8fSNly16RLQUa-iNc6Ws7H9XfqNxSqu9MhAYCg2XyywcrXRyEKJoC2Gsho'},
        { Name:'Keerthi Devarakonda',quote:"Always work hard",img:'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSG8zpeBAwHX8fSNly16RLQUa-iNc6Ws7H9XfqNxSqu9MhAYCg2XyywcrXRyEKJoC2Gsho'},
    ],
    Event: [
        {Position:"Team Lead", Name:'Bryce Ferrira',quote:"Always work hard",img:'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSG8zpeBAwHX8fSNly16RLQUa-iNc6Ws7H9XfqNxSqu9MhAYCg2XyywcrXRyEKJoC2Gsho'},
        { Name:'Gary Rodriques',quote:"Always work hard",img:'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSG8zpeBAwHX8fSNly16RLQUa-iNc6Ws7H9XfqNxSqu9MhAYCg2XyywcrXRyEKJoC2Gsho'},
        { Name:'Uttam Gupta',quote:"Always work hard",img:'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSG8zpeBAwHX8fSNly16RLQUa-iNc6Ws7H9XfqNxSqu9MhAYCg2XyywcrXRyEKJoC2Gsho'},
        { Name:'Ifra Shaikh',quote:"Always work hard",img:'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSG8zpeBAwHX8fSNly16RLQUa-iNc6Ws7H9XfqNxSqu9MhAYCg2XyywcrXRyEKJoC2Gsho'},
        { Name:'Shashank Naik',quote:"Always work hard",img:'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSG8zpeBAwHX8fSNly16RLQUa-iNc6Ws7H9XfqNxSqu9MhAYCg2XyywcrXRyEKJoC2Gsho'},
        { Name:'Yashraj Nikam ',quote:"Always work hard",img:'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSG8zpeBAwHX8fSNly16RLQUa-iNc6Ws7H9XfqNxSqu9MhAYCg2XyywcrXRyEKJoC2Gsho'},
        { Name:'Vaishnavi Yadhav',quote:"Always work hard",img:'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSG8zpeBAwHX8fSNly16RLQUa-iNc6Ws7H9XfqNxSqu9MhAYCg2XyywcrXRyEKJoC2Gsho'},
    ],    
  };

export {Teamdata_2022,Teamdata_2023};   